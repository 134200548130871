import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  Button,
  ButtonProps,
  Dropdown as NextDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownMenuProps,
  DropdownTrigger,
  SelectionMode,
} from "@nextui-org/react";
import { compact } from "lodash-es";
import { ReactNode, useMemo } from "react";
import { tv } from "tailwind-variants";

const variants = tv({
  slots: {
    trigger: "flex justify-between overflow-hidden",
  },
});
const { trigger } = variants();

export type DropdownOption = {
  value: string;
  label: ReactNode;
};

export type DropdownProps = Omit<
  InnerDropdownProps,
  "selectionMode" | "selectedValues" | "onChange"
> & {
  selectedValue?: string;
  onChange: (value?: string) => void;
  maxDropdownHeight?: string;
};

export function Dropdown({
  selectedValue,
  onChange,
  maxDropdownHeight,
  ...props
}: DropdownProps) {
  const selectedKeys = useMemo(
    () => (selectedValue ? [selectedValue] : undefined),
    [selectedValue]
  );

  const handleChange = (values?: string[]) => {
    onChange([...(values || [])][0]);
  };

  return (
    <BaseDropdown
      {...props}
      selectedValues={selectedKeys}
      onChange={handleChange}
      selectionMode="single"
      maxDropdownHeight={maxDropdownHeight}
    />
  );
}

export type MultiDropdownProps = Omit<InnerDropdownProps, "selectionMode">;

export function MultiDropdown(props: MultiDropdownProps) {
  return <BaseDropdown {...props} selectionMode="multiple" />;
}

type InnerDropdownProps = Omit<ButtonProps, "onChange"> & {
  selectedValues?: string[];
  placeholder?: string;
  options: DropdownOption[];
  onChange: (values?: string[]) => void;
  selectionMode: SelectionMode;
  ariaLabel?: string;
  classNames?: DropdownMenuProps["classNames"];
  closeOnSelect?: boolean;
  maxDropdownHeight?: string;
};

function BaseDropdown({
  selectedValues,
  selectionMode,
  placeholder,
  options,
  onChange,
  ariaLabel = "dropdown",
  className,
  classNames,
  closeOnSelect,
  maxDropdownHeight,
  ...props
}: InnerDropdownProps) {
  // Interating over the options so that order matches
  const selectedOptions =
    compact(options.filter((v) => selectedValues?.includes(v.value))) || [];
  const label =
    selectedOptions.length > 0
      ? selectedOptions.map((v) => v.label).join(", ")
      : undefined;

  return (
    <NextDropdown>
      <DropdownTrigger>
        <Button
          variant="faded"
          radius="sm"
          className={trigger({ className })}
          {...props}
        >
          {label ? (
            <span className="truncate">
              {selectedOptions.map((v) => v.label).join(", ")}
            </span>
          ) : (
            <span className="truncate font-normal text-gray-400">
              {placeholder}
            </span>
          )}
          <ChevronDownIcon className="size-4 flex-none" />
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label={ariaLabel}
        classNames={classNames}
        className={`${maxDropdownHeight ? `max-h-[${maxDropdownHeight}] overflow-y-auto` : ""}`}
        onSelectionChange={(keys) =>
          onChange([...keys].map((k) => k.toString()))
        }
        selectionMode={selectionMode}
        selectedKeys={selectedValues || []}
        closeOnSelect={closeOnSelect}
      >
        {options.map((o) => (
          <DropdownItem key={o.value}>{o.label}</DropdownItem>
        ))}
      </DropdownMenu>
    </NextDropdown>
  );
}
